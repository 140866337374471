/*----------------------------------------------------------------------------------*/
/*-------------------------------------FONTS----------------------------------------*/
/*----------------------------------------------------------------------------------*/
@font-face {
  font-family: 'Arial Rounded MT';
  src: url('../fonts/ArialRoundedMTBold.eot');
  src: url('../fonts/ArialRoundedMTBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ArialRoundedMTBold.woff2') format('woff2'),
    url('../fonts/ArialRoundedMTBold.woff') format('woff'),
    url('../fonts/ArialRoundedMTBold.ttf') format('truetype'),
    url('../fonts/ArialRoundedMTBold.svg#ArialRoundedMTBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'tracking';
  src: url('../fonts/tracking.eot?z2f1z0');
  src: url('../fonts/tracking.eot?z2f1z0#iefix') format('embedded-opentype'),
    url('../fonts/tracking.ttf?z2f1z0') format('truetype'),
    url('../fonts/tracking.woff?z2f1z0') format('woff'),
    url('../fonts/tracking.svg?z2f1z0#tracking') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'tracking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: '\e900';
  color: #494b4c;
}
.icon-location-arrow:before {
  content: '\e901';
  color: #494b4c;
}
.icon-shop:before {
  content: '\e902';
  color: #494b4c;
}
.icon-warning:before {
  content: '\e903';
  color: #494b4c;
}
.icon-checkmark-circle-big:before {
  content: '\e904';
  color: #494b4c;
}
/*----------------------------------------------------------------------------------*/
/*------------------------------RESET DEFAULT STYLES--------------------------------*/
/*----------------------------------------------------------------------------------*/
html,
body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  background: #fff;
  color: #484545;
  line-height: 1.45;
  letter-spacing: 0.02rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,
p,
a,
span,
em,
strong,
img,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
td,
tr,
form,
fieldset,
label,
select,
input,
textarea {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}
select,
input,
textarea {
  font-size: 1rem;
  vertical-align: middle;
}
textarea {
  vertical-align: top;
}
:focus {
  outline: none;
}
input[type='submit'] {
  cursor: pointer;
}
img,
fieldset {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:link,
a:visited {
  font: 1rem 'Roboto', sans-serif;
  color: #484545;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------DEFAULT CLASSES------------------------------*/
/*----------------------------------------------------------------------------------*/
.float-r {
  float: right !important;
}
.float-l {
  float: left !important;
}
.no-float {
  float: none !important;
}
.no-border {
  border: 0 !important;
}
.no-bg {
  background: none !important;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-display {
  display: none !important;
}
.display {
  display: block !important;
}
.inline {
  display: inline !important;
}
.overflow {
  overflow: hidden !important;
}
.align-r {
  text-align: right !important;
}
.align-l {
  text-align: left !important;
}
.align-c {
  text-align: center !important;
}
.clear {
  clear: both;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
a,
.btn {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
a:hover,
.btn:hover,
input[type='submit']:hover,
button:hover {
  opacity: 0.8;
}
.input-style::-webkit-input-placeholder {
  color: #4f6370;
}
.input-style::-moz-placeholder {
  color: #4f6370;
}
.input-style:-ms-input-placeholder {
  color: #4f6370;
}
.input-style:-moz-placeholder {
  color: #4f6370;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 0.78rem;
}
.wrapper {
  overflow: hidden;
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100vh;
}
#map {
  position: absolute !important;
  top: 2.225rem;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}

#root {
  position: relative;
  overflow-y: hidden;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------WRAPPER--------------------------------------*/
/*----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------*/
/*-------------------------------------HEADER---------------------------------------*/
/*----------------------------------------------------------------------------------*/
.header {
  overflow: hidden;
  position: relative;
  height: 2.225rem;
  z-index: 10;
  color: #ffffff;
  background: #2e2828;
  background: -webkit-linear-gradient(
    bottom,
    rgba(75, 71, 73, 0.95) 0%,
    rgba(46, 40, 40, 1) 100%
  );
  background: -o-linear-gradient(
    bottom,
    rgba(75, 71, 73, 0.95) 0%,
    rgba(46, 40, 40, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(75, 71, 73, 0.95)),
    to(rgba(46, 40, 40, 1))
  );
  background: linear-gradient(
    to top,
    rgba(75, 71, 73, 0.95) 0%,
    rgba(46, 40, 40, 1) 100%
  );
}

.header .container {
  display: table;
  width: 100%;
  padding: 0;
  min-height: 40px;
}
.header-title {
  display: table-cell;
  font-family: 'Arial Rounded MT', sans-serif;
  font-size: 1.17rem;
  line-height: 1;
  vertical-align: middle;
  padding-left: 0.88rem;
  padding-right: 0.75rem;
}

.header-padding-top {
  padding-top: 0.44rem;
}

.order-timing-list {
  display: table-cell;
  text-align: right;
  font-size: 0;
  letter-spacing: 0;
  padding-left: 0.75rem;
  vertical-align: top;
}
.order-timing-list > li {
  display: inline-block;
  padding: 0.37rem 1.9rem 0.35rem;
  background-color: #008908;
  font-size: 1.06rem;
  font-weight: 500;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
.order-timing-list > li.blue {
  background-color: #004163;
}
/*----------------------------------------------------------------------------------*/
/*--------------------------------------section-order-information-------------------*/
/*----------------------------------------------------------------------------------*/
.section-order-information {
  padding: 1.05rem 0;
}
.section-order-information .container {
}
.main-content {
  overflow: hidden;
  padding: 0.94rem 1.88rem 0;
}

/* .bubble-box {
  margin-bottom: 0.54rem;
} */
.bubble-box.right-alight {
  text-align: right;
}
.bubble {
  margin-top: 0.54rem;
  display: inline-block;
  padding: 0.2rem;
  min-height: 1.438rem;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.98rem;
  font-weight: 400;
  line-height: 1.6 !important;
  background-color: #ef4e39;
  -webkit-box-shadow: 0 0 0.098rem 0 rgba(0, 0, 0, 0.3),
    0 0 0.02rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0.098rem 0 rgba(0, 0, 0, 0.3),
    0 0 0.02rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem;
}

/*-------------------------------------SIDEBAR--------------------------------------*/
.sidebar {
  position: relative;
  width: 15rem;
}

.sidebar.left {
  float: left;
}
.sidebar.right {
  float: right;
  width: 15.31rem;
  padding-bottom: 1px;
  z-index: 111;
}
.info-box {
  overflow: hidden;
  border-radius: 0.46rem;
  margin-bottom: 0.94rem;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.098rem 0 rgba(0, 0, 0, 0.3),
    0 0 0.02rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0.098rem 0 rgba(0, 0, 0, 0.3),
    0 0 0.02rem 0 rgba(0, 0, 0, 0.15);
}
.box-header {
  position: relative;
  padding: 0.43rem 2rem 0.39rem 0.55rem;
  background-color: #008000;
}
.box-name {
  font-family: 'Roboto', sans-serif;
  font-size: 0.87rem;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #ffffff;
}
.box-body {
  overflow: hidden;
  padding: 0.57rem 0.54rem 0.58rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.05rem;
}
.box-body > :last-child:not(.address-list) {
  margin-bottom: 0;
}
.driver-name {
  margin-bottom: 0.12rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.45;
}
.address,
.time-status {
}
.address {
  line-height: 1.5;
}
.address-list {
  margin-top: -0.07rem;
  font-size: 0.91rem;
}
.address-list > li {
  padding: 0.34rem 0 0.34rem 0;
}
.address-list > li p {
  white-space: nowrap;
  overflow: hidden;
  background: #ffffff;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/* .address-list > li:last-child {
  padding-bottom: 0;
} */
.address-list > li.destination {
  position: relative;
  padding-right: 1.2rem;
}
.address-list > li.destination:after {
  position: absolute;
  content: '\e904';
  top: 50%;
  right: 0.6rem;
  font-family: 'tracking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 1.01rem;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #007f07;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.address-list > li.current-position {
  position: relative;
  z-index: 1;
  color: #ffffff;
  background-color: #ef4e39;
  padding: 0.24rem 0 !important;
}
.address-list > li.current-position p {
  background-color: #ef4e39;
}
.address-list > li.current-position:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: 0;
  left: -1rem;
  width: 120%;
  width: calc(100% + 2rem);
  height: 100%;
  background-color: #ef4e39;
}

.info-box .icon {
  font-size: 1rem;
}
.info-box .icon:before {
  color: #ffffff;
}
.info-box .box-header .icon {
  position: absolute;
  top: 50%;
  right: 0.95rem;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.info-box .box-header .icon-location-arrow {
  right: 0.9rem;
}
.info-box .box-header .icon-shop {
  right: 0.9rem;
  font-size: 0.94rem;
}
.info-box.notification .box-header .icon-checkmark-circle-big {
  font-size: 0.92rem;
}
.info-box.notification .box-header .icon-warning {
  right: 0.91rem;
  font-size: 0.95rem;
}
.info-box.notification .box-header .icon-shop {
  right: 1rem;
}
.info-box .box-body .icon-location-arrow {
  font-size: 1.02rem;
}
.info-box {
}
.info-box.orange .box-header {
  background-color: #ef6423;
}
.info-box.red .box-header {
  background-color: #ef4e39;
}

.info-box.notification {
  color: #ffffff;
  background-color: #008d08;
}
.info-box.notification .box-header {
  background-color: #007f07;
}
.info-box.notification .box-body {
  padding: 0.35rem 0.6rem 0.5rem;
}
.info-box.notification.blue {
  background-color: #355693;
}
.info-box.notification.blue .box-header {
  background-color: #16426e;
}

.info-box.notification.orange {
  background-color: #ff622b;
}
.info-box.notification.orange .box-header {
  background-color: #f45827;
}
.info-box.notification.red {
  background-color: #ff4c3e;
}
.info-box.notification.red .box-header {
  background-color: #f64437;
}

.notification .driver-name {
  margin-top: -0.1rem;
  margin-bottom: 0.2rem;
  font-size: 1.05rem;
  font-weight: 400;
}
.notification .time-status {
  font-weight: 500;
}

.warning-message-box {
  position: relative;
  width: 100%;
  padding: 0 0 0 3.73rem;
  color: #ffffff;
  background-color: #ff4c3e;
}
.warning-message-box:before {
  position: absolute;
  content: '\e903';
  top: 50%;
  left: 1.84rem;
  font-family: 'tracking' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 1.86rem;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.warning-message-box.notification {
  background-color: #ef4e39;
}
.warning-message {
  padding: 0.5rem 0.8rem 0.48rem 0;
  font-family: 'Roboto', sans-serif;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.3rem;
}
.warning-message span {
  padding-left: 0.05rem;
}

.info-box.driver.hide-notification {
  display: none;
  margin-bottom: 0;
}

.sidebar.left .info-box {
  position: relative;
  opacity: 0;
  z-index: 3;
  max-height: 0;
  margin-bottom: 0;
  /* margin-bottom: 0.742rem; */
  left: -120%;
  -webkit-transition: all 0.35s ease-out, opacity 0.2s ease-out;
  -o-transition: all 0.35s ease-out, opacity 0.2s ease-out;
  transition: all 0.35s ease-out, opacity 0.2s ease-out;
}
.sidebar.left .info-box.show {
  left: 0;
  opacity: 1;
  max-height: 20rem;
  margin-bottom: 0.804rem;
  -webkit-transition: all 0.35s ease-out, opacity 0s ease-out;
  -o-transition: all 0.35s ease-out, opacity 0s ease-out;
  transition: all 0.35s ease-out, opacity 0s ease-out;
}

.sidebar.right .info-box {
  position: relative;
  right: -120%;
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.35s ease-out, opacity 0.2s ease-out;
  -o-transition: all 0.35s ease-out, opacity 0.2s ease-out;
  transition: all 0.35s ease-out, opacity 0.2s ease-out;
}

.sidebar.right .info-box.show {
  right: 0;
  opacity: 1;
  max-height: 20rem;
  margin-bottom: 0.804rem;
  -webkit-transition: all 0.35s ease-out, opacity 0s ease-out;
  -o-transition: all 0.35s ease-out, opacity 0s ease-out;
  transition: all 0.35s ease-out, opacity 0s ease-out;
}

/*----------------------------------------------------------------------------------*/
/*------------------------------------RESPONSIVE------------------------------------*/
/*----------------------------------------------------------------------------------*/
@media all and (min-width: 320px) and (max-width: 1023px) {
  html,
  body {
    font-size: 12px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1119px) {
  html,
  body {
    font-size: 14px;
  }
}
@media all and (min-width: 1366px) {
  html,
  body {
    font-size: 18px;
  }
}
@media all and (min-width: 1680px) {
  html,
  body {
    font-size: 21px;
  }
}
@media all and (min-width: 1920px) {
  html,
  body {
    font-size: 24px;
  }
  /*html, body { font-size: 32px; }*/
}
@media all and (min-width: 2200px) {
  html,
  body {
    font-size: 27px;
  }
}
@media all and (min-width: 2560px) {
  html,
  body {
    font-size: 32px;
  }
}

/*------------------------------------ONLY FOR TEST------------------------------------*/
.section-test {
  position: relative;
  z-index: 5;
  overflow: hidden;
  clear: both;
  padding: 3rem 0;
}

.main-content {
  padding: 25px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .main-content {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
  }
}

@media (max-width: 480px) {
  .main-content {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.be-no-padding .main-content {
  padding: 0;
}
.be-aside .main-content {
  margin-left: 280px;
}

@media (max-width: 767px) {
  .be-aside .main-content {
    margin-left: inherit;
  }
}
.page-aside.codeditor + .main-content {
  height: 100%;
  width: 100%;
}

@media (min-width: 1200px) {
  .page-aside.codeditor + .main-content {
    position: fixed;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
  content: ' ';
  display: table;
}

.container-fluid:after {
  clear: both;
}
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .container > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}
.container-fluid .jumbotron {
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
  }
  .jumbotron h1,
  .jumbotron .h1 {
    font-size: 59px;
  }
}

.be-top-header .container-fluid {
  padding: 0;
}
@media (max-width: 767px) {
  .be-offcanvas-menu .be-top-header .container-fluid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.panel {
  margin-bottom: 18px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px 20px 20px;
}

.panel-body:before,
.panel-body:after {
  content: ' ';
  display: table;
}

.panel-body:after {
  clear: both;
}
.panel-contrast .panel-body {
  padding-top: 16px;
}

.panel-heading-contrast {
  background-color: whitesmoke;
}

.panel-body-contrast {
  background-color: whitesmoke;
}

.panel-full-color .panel-body {
  padding: 20px;
}
.panel-heading {
  padding: 20px 20px 10px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
}

.splash-container {
  max-width: 401px;
  margin: 50px auto;
}

.splash-container .panel {
  margin-bottom: 30px;
}

.splash-container .panel .panel-heading {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 40px;
  padding-bottom: 0;
}

.splash-container .panel .panel-body {
  padding: 20px 30px 15px;
}

.splash-container .panel .panel-body .be-checkbox {
  display: inline-block;
  padding: 0;
}
.splash-title {
  text-align: center;
  display: block;
  font-size: 17px;
  font-weight: 300;
}

.splash-description {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 13px;
  color: #5a5a5a;
  margin-top: 11px;
  padding-bottom: 10px;
}

.splash-footer {
  text-align: center;
}

@media (max-width: 767px) {
  .splash-container {
    margin-top: 50px;
  }
}
.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  background-color: transparent;
  border-color: #ddd;
  color: #333333;
  font-weight: 400;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: transparent;
  background-color: #333333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}
.panel-border-color {
  border-top: 3px solid #c9c9c9;
}

.panel-border-color-primary {
  border-top-color: #4285f4;
}

.panel-border-color-danger {
  border-top-color: #ea4335;
}

.panel-border-color-warning {
  border-top-color: #fbbc05;
}

.panel-border-color-success {
  border-top-color: #34a853;
}

.panel-border-color-dark {
  border-top-color: #3d3c3c;
}
.form-group {
  margin-bottom: 20px;
}
.form-group-sm .form-control {
  height: 37px;
  padding: 5px 9px;
  font-size: 12px;
  line-height: 1.6;
  border-radius: 2px;
}

.form-group-sm select.form-control {
  height: 37px;
  line-height: 37px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 37px;
  min-height: 30px;
  padding: 6px 9px;
  font-size: 12px;
  line-height: 1.6;
}
.form-group-lg .form-control {
  height: 57px;
  padding: 12px 16px;
  font-size: 15px;
  line-height: 1.7;
  border-radius: 4px;
}

.form-group-lg select.form-control {
  height: 57px;
  line-height: 57px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 57px;
  min-height: 33px;
  padding: 13px 16px;
  font-size: 15px;
  line-height: 1.7;
}
.input-lg + .form-control-feedback,
.input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 57px;
  height: 57px;
  line-height: 57px;
}

.input-sm + .form-control-feedback,
.input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 37px;
  height: 37px;
  line-height: 37px;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 11px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 29px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: ' ';
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 11px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 13px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before,
.row:after {
  content: ' ';
  display: table;
}

.row:after {
  clear: both;
}
.login-tools {
  padding-top: 18px;
}

.login-forgot-password {
  line-height: 22px;
  text-align: right;
}

.login-submit {
  padding-top: 19px;
  margin-bottom: 18px;
}

.login-submit .btn {
  width: 100%;
}

.login-submit > div:first-child {
  padding-right: 10px;
}

.login-submit > div:last-child {
  padding-left: 10px;
}

/*------------------------------------------------------------------
  [Error pages]
*/

.be-error .error-container {
  text-align: center;
  margin-top: 100px;
}
/*------------------------------------------------------------------
  Loader
*/

.be-loading {
  position: relative;
}

.be-loading:after {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 2;
}

.be-loading .be-spinner {
  display: none;
}

.be-loading-active:after {
  visibility: visible;
  opacity: 1;
}

.be-loading-active .be-spinner {
  display: block;
}

.be-loading .be-spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -20px;
  margin-right: -20px;
  z-index: 3;
}
.form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 15px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  height: 48px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control[multiple] {
  padding: 0;
}

.form-control[multiple] option {
  padding: 8px 12px;
}

input.form-control,
textarea.form-control {
  -webkit-appearance: none;
}
.parsley-errors-list.filled {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 2px 2px;
  position: relative;
  background-color: #eb6357;
  color: #ffffff;
}

.parsley-errors-list.filled:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  -moz-transform: scale(0.9999);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.parsley-errors-list.filled > li {
  list-style: none;
}
.btn > .caret,
.dropup > .btn > .caret {
  border-top-color: #000 !important;
}
.btn-xl {
  padding: 0 12px;
  font-size: 15px;
  line-height: 43px;
  border-radius: 3px;
  font-weight: 500;
}
.leaflet-control-attribution {
  display: none;
}
.be-loading {
  position: relative;
}

.be-loading:after {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 2;
}

.be-loading .be-spinner {
  display: none;
}

.be-loading-active:after {
  visibility: visible;
  opacity: 1;
}

.be-loading-active .be-spinner {
  display: block;
}

.be-loading .be-spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -20px;
  margin-right: -20px;
  z-index: 3;
}
.be-spinner svg {
  -webkit-animation: be-spinner-rotation 1.4s linear infinite;
  -o-animation: be-spinner-rotation 1.4s linear infinite;
  animation: be-spinner-rotation 1.4s linear infinite;
  stroke: #4285f4;
}

.be-spinner svg .circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-animation: be-spinner-turn 1.4s ease-in-out infinite;
  -o-animation: be-spinner-turn 1.4s ease-in-out infinite;
  animation: be-spinner-turn 1.4s ease-in-out infinite;
}

@-webkit-keyframes be-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@-o-keyframes be-spinner-rotation {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes be-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@-webkit-keyframes be-spinner-turn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-o-keyframes be-spinner-turn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -o-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes be-spinner-turn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    -o-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
.authCodeContainer {
  display: flex;
}

.authcode {
  display: block;
  width: 16%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-width: 1px;
  border-top-color: #bdc0c7;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 15px;
  transition: none;
  height: 48px;
  margin-right: 10px;
}

#map .leaflet-popup-content {
  margin: 0px;
  /* transition: all 2s linear !important; */
}

/* #map .leaflet-popup-content .bubble-box {
  transition: all 2s linear;
} */

#map .leaflet-popup-content-wrapper {
  background: none;
  box-shadow: none;
  /* transition: all 2s linear !important; */
}

#map .leaflet-popup-tip-container {
  display: none;
}

#map .leaflet-popup-tip {
  display: none;
}

#map .leaflet-popup-close-button {
  display: none;
}

#map .leaflet-popup-content {
  margin: 0;
  padding: 0;
}
